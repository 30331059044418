import { BinderInfo, BinderActionSet } from "models/BinderItem";
import { useDispatch } from "react-redux";
import {
  setBinderActionValue,
  resetBinderActionValue,
  changeIsLoading
} from "modules/binders/list/actions";
import { setBinderInfo } from "modules/binders/item/actions";
import { request } from "utils/apiClient";
import { usePersonalBinder } from "../../List/Components/PersonalBinderList/hook";
import { useArticleItem } from "../../Item/Components/Article/hook";
import { useCorporationItem } from "../../Item/Components/Corporation/hook";
import { useReduxState } from "hooks/useReduxState";
import { useState } from "react";
import { useLocation } from "react-router";
import { actionCreators as headerActionCreators } from "modules/header";

interface Result {
  binderId?: string;
  success: boolean;
  messages?: string[];
}

interface BinderResponse {
  binderId: string;
}

export interface BinderRequestParams {
  binderId: string;
  caption: string;
  description: string;
  definition: string;
  background: string;
  task: string;
  technology: string;
  topics: string;
  regulation: string;
  andKeyword: string;
  orKeyword: string;
  notKeyword: string;
  includeRelatedCorp: string;
  setShare: boolean;
  setPublish: boolean;
  query: string;
  mediaList: string;
  definitionLabel: string;
  backgroundLabel: string;
  taskLabel: string;
  technologyLabel: string;
  topicsLabel: string;
  regulationLabel: string;
  searchType: string;
}

export const useBinderAction = () => {
  const binderActionState = useReduxState(
    state => state.binders.list.binderAction
  );
  const binderInfo = useReduxState(s => s.binders.item.binderInfo);
  const limit = useReduxState(s => s.binders.list.personalLimit);
  const dispatch = useDispatch();
  const { fetchPersonalBinders } = usePersonalBinder();
  const { fetchArticleList } = useArticleItem();
  const { fetchCorporationList } = useCorporationItem();

  const changeIsLoadingFlg = (bool: boolean) => {
    dispatch(changeIsLoading(bool));
  };

  const makeComplementParam = (params: BinderRequestParams) => {
    return {
      binderId: params.binderId,
      definition: params.definition,
      background: params.background,
      task: params.task,
      technology: params.technology,
      topics: params.topics,
      definitionLabel: params.definitionLabel,
      backgroundLabel: params.backgroundLabel,
      taskLabel: params.taskLabel,
      technologyLabel: params.technologyLabel,
      topicsLabel: params.topicsLabel,
      regulation: params.regulation,
      regulationLabel: params.regulationLabel
    };
  };

  const createBinder = async (params: BinderRequestParams): Promise<Result> => {
    if (!params.caption) params["caption"] = "名称未設定";
    const replacedParams = params;
    try {
      const response: BinderResponse = await request(
        "/binder/save",
        replacedParams,
        "POST"
      );
      dispatch(
        headerActionCreators.requestUpdate({ shouldLoadCollection: true })
      );
      return { success: true, binderId: response.binderId };
    } catch (error) {
      return {
        success: false,
        messages: error.body.details.map((item: any) => item.message)
      };
    }
  };

  const location = useLocation();

  const updateBinder = async (
    params: BinderRequestParams,
    binderId: string,
    onBinderUpdated: () => void,
    isArticle = false
  ): Promise<Result> => {
    changeIsLoadingFlg(true);
    try {
      if (!params.caption) params["caption"] = "名称未設定";
      const replacedParams = params;
      const response = await request<BinderInfo>(
        "/binder/update",
        replacedParams,
        "POST"
      );
      dispatch(
        headerActionCreators.requestUpdate({ shouldLoadCollection: true })
      );
      onBinderUpdated();
      dispatch(setBinderInfo(response));
      if (binderInfo) {
        await fetchCorporationList(binderInfo.binderId);
      }
      if (isArticle) {
        await fetchArticleList(binderId);
      } else {
        if (
          location.pathname === "/binder/personal" ||
          location.pathname === "/"
        ) {
          await fetchPersonalBinders(limit);
        } else {
          await fetchArticleList(binderId);
        }
      }
      changeIsLoadingFlg(false);
      return { success: true, binderId: response.binderId };
    } catch (error) {
      changeIsLoadingFlg(false);
      return {
        success: false,
        messages: error.body?.details?.map((item: any) => item.message) ?? []
      };
    }
  };

  const editComplement = async (
    params: BinderRequestParams,
    binderId: string,
    isArticle = false
  ): Promise<Result> => {
    changeIsLoadingFlg(true);
    try {
      const complementUpdateParam = makeComplementParam(params);
      const complementResponse = await request<BinderInfo>(
        "/binder/complement-update",
        complementUpdateParam,
        "POST"
      );
      dispatch(setBinderInfo(complementResponse));
      if (isArticle) {
        await fetchArticleList(binderId);
      } else {
        if (
          location.pathname === "/binder/personal" ||
          location.pathname === "/"
        ) {
          await fetchPersonalBinders(limit);
        } else {
          await fetchArticleList(binderId);
        }
      }
      changeIsLoadingFlg(false);
      return { success: true };
    } catch (error) {
      changeIsLoadingFlg(false);
      return {
        success: false,
        messages: error.body?.details?.map((item: any) => item.message) ?? []
      };
    }
  };

  const [cloneCaption, setCloneCaption] = useState("");

  const cloneBinder = async (binderId: string, defaultCaption: string) => {
    const response = await request<BinderInfo>(
      "/binder/clone",
      { binderId, caption: cloneCaption || defaultCaption },
      "POST"
    );
    dispatch(setBinderInfo(response));
    dispatch(
      headerActionCreators.requestUpdate({ shouldLoadCollection: true })
    );
    return response.binderId;
  };

  const shareBinder = async (binderId: string, isSharing: boolean) => {
    try {
      await request("/binder/share", { binderId, setShare: isSharing }, "POST");
      dispatch(
        headerActionCreators.requestUpdate({ shouldLoadCollection: true })
      );
      return { success: true };
    } catch {
      return { success: false };
    }
  };

  const publishBinder = async (binderId: string, isPublishing: boolean) => {
    return request(
      "/binder/publish",
      { binderId, setPublish: isPublishing },
      "POST"
    );
  };

  const setBinderActionModal = async (value: BinderActionSet) => {
    dispatch(setBinderActionValue(value));
  };

  const closeBinderActionModal = () => dispatch(resetBinderActionValue());

  return {
    binderActionState,
    changeIsLoadingFlg,
    createBinder,
    updateBinder,
    editComplement,
    cloneBinder,
    shareBinder,
    publishBinder,
    setBinderActionModal,
    closeBinderActionModal,
    cloneCaption,
    setCloneCaption,
    limit
  };
};
