import { request } from "utils/apiClient";
import { useLocation, useHistory } from "react-router";
import { SessionState, CollectionAuthority, CorpAuth } from "models/Auth";
import {
  setSessionState,
  setLoginOptions,
  setError,
  setIsAuthorizing
} from "modules/auth/temporary/actions";
import { setAuth as setSearchTabAuth } from "modules/auth/searchTab/actions";
import { setCorpAuth } from "modules/auth/corp/actions";
import { useReduxState } from "hooks/useReduxState";
import {
  authorizeByToken,
  validateChargeCode,
  handleAuthError,
  authorizeByPlanDownload
} from "utils/helper/auth";
import { useDispatch } from "react-redux";
import { Response } from "models/api/Logout";
import { actionCreators as headerActionCreators } from "modules/header";
import { get } from "lodash";
import {
  setUserInfo,
  clearRedirectPath,
  clearError,
  setRedirectPath,
  setCollectionAuthority
} from "modules/auth/saved/actions";
import { loadErrorMessage } from "utils/helper/common";
import { actionCreators as sourceSelectorActionCreators } from "modules/common/SourceSelector";
import { useClearPersist } from "hooks/useClearPersist";
import { useScreeningTree } from "hooks/useScreeningTree";
import { removeTokenAndId, removeMailAddress } from "utils/helper/localStorage";
import { actionCreators as settingPersonalActionCreators } from "modules/setting/personal/system";
import { clearState as screeningCorpClear } from "modules/tool/screening/corp/modernItem/actions";

interface AuthListResponse {
  news: boolean;
  corp: boolean;
  disclosure: boolean;
  magazine: boolean;
  macroStat: boolean;
  industry: boolean;
  ir: boolean;
  governmentOffice: boolean;
  binder: boolean;
}

export const useAuth = () => {
  const dispatch = useDispatch();
  const sessionState = useReduxState(
    state => state.auth.temporary.sessionState
  );
  const loginOptions = useReduxState(state => state.auth.temporary.options);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const { clearMainPersist, clearUserPersist } = useClearPersist();
  const { fetchFilterTreeList, fetchDisplayTree } = useScreeningTree();

  const fetchAuthList = async () => {
    const authResponse = await request<AuthListResponse>(
      "/searchlist/tab-authority"
    );
    dispatch(
      setSearchTabAuth({
        corpVisible: authResponse.corp,
        industryVisible: authResponse.industry,
        newsVisible: authResponse.news,
        magazineVisible: authResponse.magazine,
        irVisible: authResponse.ir,
        collectionVisible: authResponse.binder,
        dataVisible: authResponse.disclosure,
        governmentVisible: authResponse.governmentOffice,
        macroVisible: authResponse.macroStat
      })
    );
  };

  const fetchCollectionAuthority = async () => {
    const response = await request<CollectionAuthority>(
      "/binder/function-authority"
    );
    dispatch(setCollectionAuthority(response));
  };

  const fetchCorpAuthList = async () => {
    const authResponse = await request<CorpAuth>("/common/tab/corp-basics");

    dispatch(
      setCorpAuth({
        listed: authResponse.listed,
        overseas: authResponse.overseas,
        unlisted: authResponse.unlisted,
        unlistedKepple: authResponse.unlistedKepple,
        unlistedOverseas: authResponse.unlistedOverseas,
        unlistedTdb: authResponse.unlistedTdb
      })
    );
  };

  // 認証後の処理
  const afterAuthorizing = () => {
    dispatch(clearRedirectPath());
    // LocalStorage をクリアしておく
    clearMainPersist();
    removeMailAddress();
    // 認証後に企業種別選択値を取得する
    dispatch<any>(sourceSelectorActionCreators.getSelection()).then(
      (response: any) => {
        // 従量課金ポップアップを表示するかのフラグを更新
        const paymentConfirmFlg = response?.value?.paymentConfirmFlg;
        if (paymentConfirmFlg) {
          dispatch(
            settingPersonalActionCreators.paymentConfirmFlgUpdate({
              paymentConfirmFlg
            })
          );
        }
      }
    );
    // コレクション操作権限を取得する
    fetchCollectionAuthority();
    // タブ権限を取得
    fetchAuthList();
    // 企業タブ権限を取得
    fetchCorpAuthList();
    // 前回認証時のエラーがある場合はクリアしておく
    dispatch(clearError());
  };

  // セッションの有無を確認
  const requestTokenValidation = async () => {
    const authorized = get(location, "state.authorized");
    if (authorized) {
      afterAuthorizing();
      // 認証後のフラグを消しておく
      history.replace(location.pathname + location.search, {});
      // 認証後の遷移は必ずセッションがあるので問い合わせをスキップ
      return;
    }

    dispatch(setSessionState("unknown"));
    try {
      const response = await request<SessionState>("/token/validation");
      dispatch(setSessionState(response));
      if (response === "active") {
        dispatch(clearRedirectPath());

        if (searchParams.has("directAccessAuthCode")) {
          searchParams.delete("directAccessAuthCode");
          history.replace(`${location.pathname}?${searchParams}`);
        }
      }
    } catch (_) {
      dispatch(setSessionState("inactive"));
    }
  };

  const directAccessCode = searchParams.get("directAccessAuthCode");

  const authorize = async (directAccessAuthCode: string) => {
    dispatch(setSessionState("unknown"));
    try {
      const chargeCode1 = searchParams.get("j_chargecode1") || "";
      const chargeCode2 = searchParams.get("j_chargecode2") || "";

      if (chargeCode1) {
        const chargeCodeError = validateChargeCode(chargeCode1, chargeCode2);
        if (chargeCodeError.chargeCode1 || chargeCodeError.chargeCode2) {
          dispatch(setError({ other: chargeCodeError.chargeCode2 }));
          history.push("/login");
          return;
        }
      }

      const response = await authorizeByToken({
        directAccessAuthCode,
        chargeCode1,
        chargeCode2
      });
      dispatch(setUserInfo(response.loginInfo));
      dispatch(setLoginOptions(response.optionInfos));
      dispatch(setSessionState("active"));

      // オプションがない場合は、ツリー取得処理を実行
      if (response.optionInfos && response.optionInfos.length === 0) {
        fetchFilterTreeList({ currency: "1" });
        fetchDisplayTree();
      }

      clearUserPersist();
      afterAuthorizing();

      searchParams.delete("directAccessAuthCode");
      history.replace(`${location.pathname}?${searchParams}`);
    } catch (error) {
      const authError = handleAuthError(error);
      if (authError) {
        dispatch(setError(authError));
      }
      history.push("/login");
    }
  };

  const redirectLogin = () => {
    const apiErrorCode = searchParams.get("apiErrorCode");
    if (apiErrorCode) {
      const message = loadErrorMessage(apiErrorCode);
      if (message) dispatch(setError({ other: message }));
    }

    // 特定のページはハッシュ情報を保持する必要がある
    const pathname = location.pathname;
    const needHashUrls = ["/recommendation"];
    if (needHashUrls.includes(pathname)) {
      dispatch(
        setRedirectPath(`${pathname}${location.search}${location.hash}`)
      );
    } else {
      dispatch(setRedirectPath(`${pathname}${location.search}`));
    }
    history.push("/login");
  };

  // 日経IDログアウト後のリダイレクト先
  const nidLogoutRedirect = "/post-logout";

  const logout = async (location = window.location) => {
    dispatch(setSessionState("unknown"));
    try {
      const response = await request<Response>("/logout");
      if (response.logoutUrl) {
        const redirectOrigin =
          MODE === "production" && API_DOMAIN
            ? `https://${API_DOMAIN}`
            : location.origin;
        location.href = `${response.logoutUrl}?client_id=${response.rpid}&post_logout_redirect_uri=${redirectOrigin}${nidLogoutRedirect}`;
      } else {
        dispatchLogoutActions();
      }
    } catch (_) {
      // ログアウト失敗
      dispatch(setSessionState("active"));
    }
  };

  // 日経IDログアウト後にアプリケーション側のログアウト処理を行う
  const handleNidLogout = (pathname: string) => {
    if (pathname === nidLogoutRedirect) {
      dispatchLogoutActions();
    }
  };

  const dispatchLogoutActions = () => {
    dispatch(setSessionState("logout"));
    dispatch(clearRedirectPath());
    dispatch(headerActionCreators.clearFilter());
    dispatch(settingPersonalActionCreators.clearPersonalSystem());
    dispatch(screeningCorpClear());
    clearUserPersist();
    clearMainPersist();
    removeTokenAndId();
    removeMailAddress();
  };

  const planDownloadPostAuthorizing = async (downloadToken: string) => {
    try {
      const response = await authorizeByPlanDownload(downloadToken);

      dispatch(setSessionState("active"));
      dispatch(setUserInfo(response.loginInfo));
      dispatch(setLoginOptions(response.optionInfos));

      dispatch(setIsAuthorizing(false));
    } catch (error) {
      const authError = handleAuthError(error);
      if (authError) {
        dispatch(setError(authError));
      }
      dispatch(setSessionState("inactive"));
      history.push("/login");
    }
  };

  return {
    sessionState,
    requestTokenValidation,
    directAccessCode,
    authorize,
    logout,
    handleNidLogout,
    loginOptions,
    redirectLogin,
    planDownloadPostAuthorizing
  };
};
